export const LOGO_IMAGE_PATH = '/assets/images/logo.png';
export const BAND_LOGO_IMAGE_PATH = '/assets/images/band-logo.png';
export const BANNER_IMAGE_PATH = '/assets/images/banner.jpg';
export const AUTH_BACKGROUND_IMAGE_PATH = '/assets/images/background/auth.png';
export const IMAGE_PLACEHOLDER_IMAGE_PATH =
  '/assets/images/icons/image-placeholder.jpg';
export const TERMINAL_FRAMEWORK = '/assets/images/terminal/framework.png';
export const TERMINAL_DISPLAY = '/assets/images/terminal/display.png';
export const TERMINAL_BATTERY = '/assets/images/terminal/battery.svg';
export const TERMINAL_LINK = '/assets/images/terminal/link.svg';
export const TERMINAL_TEAM_LOGO = '/assets/images/terminal/Beitar.png';