import { memo } from 'react';

const TeamLogo = ({ size = 30, team }) => {
  if (!team.name) {
    return null;
  }
  
  return (
    <img 
      src={`/assets/images/teams/${team.name.replace(" ", "_")}.png`} 
      width={size}
      height={size}
      style={{ margin: "0 5px" }}
    />
  );
};

export default memo(TeamLogo);
